<template>
  <div>
    <div class="bg">
       <nav-bar></nav-bar>
       <div class="main ovh">
         <div class="bigword">让人们<br> 因你的价值<br> 喝彩</div>
         <div class="smlword fl">
           <span>飞观是商业和思想的视频严选订阅平台</span>，通过严选全球有价值的视频内容,帮助用户在信息粉尘时代，提升信息获取质量和效率，启迪思想，升级认知。
            <br><br> 邀请拥有优质商业或思想内容创作能力的创作者入驻飞观。
         </div>
         <div class="btn fr"><a href="http://wj.qq.com/s2/5151988/7f6f" target="_blank" rel="noopener noreferrer">立即入驻</a></div>
       </div>
    </div>
    <div class="content ovh">
      <div class="words fr">
        <div class="con-bigword">优秀的<br> 内容<br> 不再被埋没</div>
        <div class="con-smlword">
          飞观的存在意义只有一件事，就是<strong>让真正优秀的内容匹配到对的人群。</strong>飞观<strong>希望帮助有具有优秀内容制作能力的机构和组织，</strong>成长为飞观平台上的明星账号，使得他们能够精准连接泛商业人群，实现自身的商业价值和社会价值。
        </div>
      </div>
      <div class="fl con-img"><img src="./status/img3.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访"></div>
    </div>
    <div class="bg-gray ovh">
      <div class="bg-gray-main">
        <div class="words fl">
          <div class="con-bigword">我们的<br> 目标<br> 用户</div>
          <div class="con-smlword">
            飞观专注为当下<strong>新中产阶层</strong>提供<strong>独立见解、多元思考、深度打磨</strong>的优质视频内容。飞观收录国内外一流的<strong>商业题材纪录片</strong>,汇聚耀眼的大师思想精华，定期输出国内外泛商业、思想和演讲视频。打开飞观!享受优质、有趣、多元的视频严选内容。
          </div>
        </div>
        <div class="fr con-img1"><img src="./status/img2.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访"></div>
      </div>
    </div>
    <div class="content ovh h920">
      <div class="words fr">
        <div class="con-bigword">我们的<br>KOL<br>扶持计划</div>
        <div class="con-smlword">
            <strong>飞观</strong>聚焦视频内容行业趋势，计划与<strong>100+KOL</strong>建立合作，通过流量分发将分散的商业KOL凝聚，形成可以<strong>改变传统市场格局的优质内容新力量</strong>，助力KOL的腾飞成长。
        </div>
      </div>
      <div class="fl con-img2"><img src="./status/img1.png" alt="飞观,商业,思想,文化,严选,视频,学习,知识,短视频,轻视频,fillgreat,纪录片,BBC,TED,演讲,采访"></div>
    </div>
    <div class="line"></div>
    <div class="join">成为飞观的一员</div>
    <div class="joinbtn"><a href="http://wj.qq.com/s2/5151988/7f6f" target="_blank" rel="noopener noreferrer">入驻飞观</a></div>
    <foot></foot>
  </div>
</template>

<script>
import NavBar from '@/components/nav.vue'
import Foot from '@/components/foot.vue'
export default {
  components: {
    NavBar,
    Foot
  }
}
</script>

<style scoped>
   a {
     display: block;
     width: 100%;
     height: 100%;
     color: black;
     border-radius: 7px;
   }
   .bg {
     background-image: url('./status/settlebanner.png');
     background-size: cover;
     padding-top: 24px;
     height: 653px;
   }
   .main {
     width: 1160px;
     margin: 0 auto;
   }
   .bigword {
     width:310px;
     font-size:62px;
     font-family:PingFang-SC-Heavy,PingFang-SC;
     font-weight:800;
     color:rgba(255,255,255,1);
     line-height:87px;
     margin-top: 90px;
     margin-bottom: 31px;
     text-align: left;
   }
   .smlword {
     width:500px;
     height:138px;
     font-size:18px;
     font-family:PingFang-SC-Heavy,PingFang-SC;
     font-weight:800;
     color:rgba(255,255,255,1);
     line-height:25px;
     text-align: justify;
     margin-bottom: 84px;
   }
   .smlword span {
     font-size:18px;
     font-family:PingFang-SC-Heavy,PingFang-SC;
     font-weight:500;
     color:rgb(240, 240, 240);
   }
   .btn {
     width:149px;
     height:54px;
     border-radius: 7px;
     line-height: 54px;
     background-color: #fff;
     margin-top: 72px;
     font-size:18px;
     font-family:PingFang-SC-Heavy,PingFang-SC;
     font-weight:800;
     color:rgba(0,0,0,1);
     cursor: pointer;
   }
   .btn:hover a {
     background-color: #f82828;
     color: white;
   }
   .content {
     width: 1160px;
     height: 650px;
     margin: 0 auto;
   }
   .bg-gray {
     width: 100%;
     height: 650px;
     background:rgba(247,247,250,1);;
   }
   .bg-gray-main {
     width: 1160px;
     height: 650px;
     margin: 0 auto;
   }
   .con-bigword {
     width:372px;
     height:261px;
     font-size:62px;
     font-family:PingFang-SC-Heavy,PingFang-SC;
     font-weight:800;
     color:rgba(54,54,54,1);
     line-height:87px;
     margin: 127px 133px 31px 0;
     text-align: left;
   }
   .con-smlword {
     width:500px;
     font-size:18px;
     font-family:PingFang-SC-Regular,PingFang-SC;
     font-weight:400;
     color:rgba(54,54,54,1);
     line-height:25px;
     text-align: justify;
   }
   .con-img {
     width: 590px;
     margin-top: 188px;
   }
   .con-img1 {
     width: 480px;
     margin-top: 139px;
   }
   .con-img2 {
     width: 472px;
     margin-top: 127px;
   }
   .con-img img,.con-img1 img,.con-img2 img {
     width: 100%;
   }
   .line {
     width:1060px;
     height:5px;
     background:rgba(247,247,250,1);
     margin: 0 auto 95px;
   }
   .join {
     width:464px;
     height:65px;
     font-size:46px;
     font-family:PingFang-SC-Heavy,PingFang-SC;
     font-weight:800;
     color:rgba(54,54,54,1);
     line-height:65px;
     margin: 0 auto 47px;
   }
   .joinbtn {
     width:196px;
     height:53px;
     background:rgba(248,40,40,1);
     font-size:16px;
     font-family:PingFangSC-Regular,PingFang SC;
     font-weight:800;
     color:rgba(255,255,255,1);
     line-height:53px;
     letter-spacing:1px;
     margin: 0 auto 109px;
     cursor: pointer;
   }
   .joinbtn a {
     color: white;
   }
   @media screen and (max-width: 1200px) {
     .main,.content,.bg-gray-main {
       width: 878px;
     }
     .con-img2 {
       width: 350px;
     }
     .con-img,.con-img1{
       width: 350px;
       margin-top: 275px;
     }
     .con-smlword {
       width: 400px;
     }
     .con-bigword {
       margin-right: 56px;
     }
     .line {
       width:607px;
       height:5px;
       background:rgba(247,247,250,1);
       margin: 0 auto;
       margin-bottom: 93px;
     }
   }
   @media screen and (max-width: 992px) {
     .bg {
       padding-top: 0;
     }
    .main {
      width: 100%;
    }
    .bigword,.smlword {
      margin-left: 56px;
    }
    .bigword {
      margin-top: 90px;
    }
    .btn {
      margin-right: 58px;
    }
    .content {
      width: 100%;
      height: 795px;
    }
    .bg-gray-main {
      width: 100%;
    }
    .bg-gray {
      width: 100%;
      height: 860px;
    }
    .h920 {
      height: 820px;
    }
    .words {
      width: 100%;
      float: none;
    }
    .con-bigword {
      margin: 56px auto 31px;
      text-align: center;
    }
    .con-smlword {
      margin: 0 auto 43px;
    }
    .con-img,.con-img1,.con-img2 {
      float: none;
      margin: 0 auto;
    }
   }
   @media screen and (max-width: 768px) {
     .smlword {
       width: 247px;
     }
     .line {
       width: 72%;
     }
     .bigword {
       font-size: 52px;
     }
     .con-bigword {
       width: 100%;
       font-size: 48px
     }
     .con-smlword {
       width: 57%;
     }
     .con-img,.con-img1,.con-img2{
       width: 58%;
     }
     .join {
       width: 80%;
       font-size: 38px;
     }
   }
   @media screen and (max-width: 576px) {
     .bigword {
       width: 59%;
       font-size: 37px;
     }
     .smlword,.smlword span {
       width: 80%;
       font-size: 14px;
       margin-bottom: 0px;
     }
      .btn {
        margin: 225px auto 0;
        float: none;
      }
      .btn a {
        color: #000;
      }
   }
</style>
